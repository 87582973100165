<template>
  <div style="height:100%">
    <!-- 裂变海报页 -->
    <div id="posterHtml">
      <img id="bg-img" src="../assets/bg.jpg" alt="" />

      <!-- 顶部个人信息 -->
      <div class="profile">
        <img class="user-avatar" :src="user.avatar" alt="">
        <div class="profile-right">
          <span class="user-name">{{ user.username }}</span>
          <span class="user-tip">已加入测评，邀请你一起</span>
        </div>
      </div>

      <!-- 底部二维码 -->
      <div class="qrcode">
        <vue-qr class="qrcode-img" :text="user.front_qrcode" :size="100" :margin="2"></vue-qr>
      </div>
    </div>

    <!-- loading效果 -->
    <div class="loading-box" v-show="loading">
      <div class="loading">
        <img src="../assets/loading.gif" alt="">
        <p>Loading...</p>
      </div>
    </div>

   <!-- 底部提示文字 -->
    <div class="tips">
      <div class="triangle"></div>
      <span>长按保存图片,发送给朋友</span>
    </div>

    <!-- 菜单 -->
    <div class="menu">
      <div id= "evaluation" class="left" @click="goHome">返回首页</div>
    </div>
    
  </div>
</template>

<script>
import html2canvas from "html2canvas";
import VueQr from "vue-qr";
export default {
  name: "Index",
  components: {
    VueQr,
  },
  data () {
    return {
      user: {},
      loading: false
    };
  },

  methods: {
    goHome () {
      this.$router.push('/')
    },

    //从本地获取用户信息
    getProfileInfo () {
      this.user = JSON.parse(localStorage.getItem('user'))
    },

    saveHtml2Image () {
      window.pageYoffset = 0;
      document.documentElement.scrollTop = 0;
      document.body.scrollTop = 0;
      // 防止宣传页和裂变页在来回切换时 一直创建img
      if (document.getElementById('poster-Img')) {
        document.getElementById('poster-Img').style.display = "block"
        return
      }

      html2canvas(document.querySelector('#posterHtml'), {
        useCORS: true,
        allowTaint: true,
      }).then(async canvas => {
        const img = document.createElement("img");
        img.src = canvas.toDataURL("image/png")
        document.body.appendChild(img);
        img.style.cssText =
          "width:100%;height:100%;position:absolute;top:0;left:0;right:0;bottom:0;opacity:0;z-index:3;";
        img.id = "poster-Img"
      });

    },


  },

  created () {
    this.getProfileInfo();
  },
  mounted () {

    //延迟1000毫秒再生成页面完整图片，避免二维码未能加入到图片中
    setTimeout(this.saveHtml2Image, 1000);

  },

};
</script>

<style scoped>
#posterHtml {
  width: 100%;
  height: 18.4rem;
  /* height: 100%; */
  /* background: url("../assets/bg.jpg"); */
  background-repeat: no-repeat;
  background-size: 100% auto;
  -webkit-background-size: 100% auto;
  /* background-attachment: fixed; */
  /* -webkit-background-attachment: fixed; */
}
#bg-img {
  width: 100%;
  /* height: 100%; */
}
.profile {
  position: absolute;
  top: 0.427rem;
  left: 0.417rem;
  z-index: 2;
  display: flex;
}
.user-avatar {
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 50%;
  vertical-align: middle;
}
.profile-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 0.053rem;
  margin-left: 0.133rem;
}
.user-name {
  font-size: 14px;
  font-family: "KaiTi";
  color: #fff;
}
.user-tip {
  font-size: 12px;
  font-family: "KaiTi";
  color: #fff;
}
.qrcode {
  width: 2.167rem;
  height: 2.167rem;
  position: absolute;
  /* right: 0.917rem;
  bottom: 0.167rem; */
  right: 0.853rem;
  bottom: 0.72rem;
  z-index: 2;
}
.qrcode .qrcode-img {
  width: 2.167rem;
  height: 2.167rem;
}

.loading-box {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 99;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loading {
  text-align: center;
}

/* 底部提示文字 */
.tips {
  width: 100%;
  height: 1.6rem;
  background-color: #ff7f50;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.tips span {
  font-size: 20px;
  font-weight: 700;
  font-family: "KaiTi";
}
.tips .triangle {
  width: 0;
  height: 0;
  border-width: 0 20px 20px;
  border-style: solid;
  border-color: transparent transparent #ff7f50;
  position: absolute;
  top: -12px;
  left: 30px;
}

/* 菜单 */
.menu {
  width: 100%;
  height: 1.6rem;
  background-color: #ffffff;
  left: 0;
  bottom: 0;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 0px red, /*左边阴影*/ 0px 0px 3px gray,
    /*上边阴影*/ 0px 0px 0px green, /*右边阴影*/ 0px 0px 0px blue; /*下边阴影*/
}
.menu div {
  width: 2.667rem;
  height: 35px;
  line-height: 35px;
  font-size: 0.533rem;
  text-align: center;
  color: #e05331;
}
</style>