import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import router from './router'
import 'amfe-flexible'
import echarts from 'echarts'
import "./assets/icon/iconfont.css"

Vue.prototype.$echarts = echarts

Vue.config.productionTip = false
if (module.hot) {
  module.hot.accept();
}

new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
