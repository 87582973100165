<template>
  <v-container id="timeline">
    <v-row justify="space-around">
      <v-card width="400" id="card-container">
        <v-img
          height="200px"
          src="../assets/bee.jpg"
          @load="loaded"
        >
          <v-app-bar flat color="rgba(0, 0, 0, 0)">
            <v-app-bar-nav-icon color="white"></v-app-bar-nav-icon>

            <v-toolbar-title class="title white--text pl-0">
              Messages
            </v-toolbar-title>

            <v-spacer></v-spacer>

            <v-btn color="white" icon>
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </v-app-bar>

          <v-card-title class="white--text mt-8">
            <v-avatar size="56">
              <img
                alt="user"
                src="../assets/cabbage.jpg"
              />
            </v-avatar>
            <p class="ml-3">John Doe</p>
          </v-card-title>
        </v-img>

        <v-card-text>
          <div class="font-weight-bold ml-8 mb-2">Today</div>

          <v-timeline align-top dense>
            <v-timeline-item
              v-for="message in messages"
              :key="message.time"
              :color="message.color"
              small
            >
              <div>
                <div class="font-weight-normal">
                  <strong>{{ message.from }}</strong> @{{ message.time }}
                </div>
                <div>{{ message.message }}</div>
              </div>
            </v-timeline-item>
          </v-timeline>
          <div id="content"></div>
        </v-card-text>
      </v-card>
    </v-row>
  </v-container>
</template>


<script>
import html2canvas from "html2canvas";
import Canvas2Image from "canvas2image";
export default {
  data: () => ({
    messages: [
      {
        from: "You",
        message: `Sure, I'll see you later.`,
        time: "10:42am",
        color: "deep-purple lighten-1",
      },
      {
        from: "John Doe",
        message: "Yeah, sure. Does 1:00pm work?",
        time: "10:37am",
        color: "green",
      },
      {
        from: "You",
        message: "Did you still want to grab lunch today?",
        time: "9:47am",
        color: "deep-purple lighten-1",
      },
    ],
  }),
  methods: {
    loaded: () => {

      var opts = {
        useCORS: true, //允许加载跨域的图片
        // scale: scaleBy, // 添加的scale 参数
        // canvas:canvas, //自定义 canvas
        // width:width, //dom 原始宽度
        // height:height //dom 原始高度
      };
      // document.getElementById('card-container')
      // document.getElementsByClassName('justify-space-around')[0]
      html2canvas(document.body, opts).then((canvas) => {
        document.body.appendChild(canvas);

        let scale = 1;
        var img = Canvas2Image.convertToImage(
          canvas,
          canvas.width,
          canvas.height
        );
        img.style.width = canvas.width / scale + "px";
        img.style.height = canvas.height / scale + "px";
        document.getElementById("content").appendChild(img);
      });
    },
  },
};
</script>