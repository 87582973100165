<template>
  <div>
    <!-- 海报html元素 -->
    <div
      id="posterHtml"
      :style="{
        backgroundImage: 'url(' + posterHtmlBg + ')',
      }"
      v-show="true"
    >
      <div>{{ posterContent }}</div>
      <!-- 二维码 -->
      <div id="content">
        <p>霍格沃兹</p>
        <h1>霍格沃兹测试学院</h1>

        <h2>霍格沃兹</h2>

        <h3>霍格沃兹</h3>

        <p>霍格沃兹</p>

        <p>霍格沃兹</p>
      </div>
      <div class="qrcode">
        <div id="qrcodeImg"></div>
      </div>
    </div>
    <div id="png"><img :src="posterImg" /></div>
  </div>
</template>

<style>
#content {
  color: white;
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
}
#qrcodeImg {
  position: absolute;
  right: 5px;
  bottom: 5px;
  width: 200;
}

#posterHtml {
  position: relative;
  width: 500px;
  height: 1000px;
}

#png img {
  width: 500px;
  height: 1000px;
}
</style>

<script>
import QRCode from "qrcodejs2";
import html2canvas from "html2canvas";
export default {
  data () {
    return {
      posterContent: "文案内容文案内容文案内容", // 文案内容
      posterHtmlBg: require("../assets/bg.jpg"), // 背景图
      posterImg: "", // 最终生成的海报图片
    };
  },
  methods: {
    createQrcode (text) {
      // 生成二维码
      const qrcodeImgEl = document.getElementById("qrcodeImg");
      // qrcodeImgEl.innerHTML = "";
      let qrcode = new QRCode(qrcodeImgEl, {
        width: 80,
        height: 100,
        colorDark: "#000000",
        colorLight: "#ffffff",
        correctLevel: QRCode.CorrectLevel.H,
      });
      qrcode.makeCode(text);
    },

    createPoster () {
      // 生成海报
      const vm = this;
      const domObj = document.getElementById("posterHtml");
      html2canvas(domObj, {
        useCORS: true,
        allowTaint: false,
        logging: false,
        letterRendering: true,
        onclone (doc) {
          let e = doc.querySelector("#posterHtml");
          e.style.display = "block";
        },
      }).then(function (canvas) {
        // 在微信里,可长按保存或转发
        vm.posterImg = canvas.toDataURL("image/png");
      }).catch(error => {
        console.error(error)
      });
    },
  },
  mounted () {
    this.createQrcode("https://ceshiren.com");
    this.createPoster();
  },
};
</script>
